import React from 'react';

import { FirebaseContext } from '../../utils/firebase-provider.js';
import { emissionCalculator } from '../../utils/emission-utils/index.js';
import { emissionQuestions } from '../../utils/emission-utils/EmissionQuestions.js';
import EmissionCategory from './EmissionCategory';

import '../../styles/global.scss';
import '../../styles/emissions.scss';

const EmissionCategories = () => {
    const context = React.useContext(FirebaseContext);
    const userProfile = context.userProfile;
    const { result: emissionResult, error: errorCalculator } = React.useMemo(
        () => emissionCalculator(userProfile),
        [userProfile]
    );
    const emissionQuestionsSize = Object.keys(emissionQuestions).length;
    return (
        <div className="emissionCategories">
            {Object.entries(emissionQuestions).map(([category, questions], index) => {
                const co2generated = emissionResult.hasOwnProperty(category)
                    ? emissionResult[category]
                    : 0;
                return (
                    <EmissionCategory
                        key={`emission-category-${index}`}
                        displayAllBorder={index + 1 == emissionQuestionsSize}
                        title={category}
                        questions={questions}
                        co2generated={co2generated}
                    />
                );
            })}
            <a onClick={() => context.cleanUserProfile()}></a>
            {errorCalculator ? (
                <div>
                    <p>Error calculating emissions</p>
                    <pre>{errorCalculator}</pre>
                </div>
            ) : null}
            <br />
        </div>
    );
};

export default EmissionCategories;
