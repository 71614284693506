import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/global.scss';
import '../../styles/emissions.scss';

const EmissionSelectQuestion = props => {
    return (
        <div className="selectQuestion">
            {props.options.map((option, index) => {
                const selectedClass = option.value == props.value ? 'selectQuestionCurrent' : '';
                return (
                    <span
                        id={'select' + index}
                        className={'selectQuestionOption ' + selectedClass}
                        onClick={() => props.onChange(option.value)}
                    >
                        {option.name}
                    </span>
                );
            })}
        </div>
    );
};

EmissionSelectQuestion.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
        })
    ).isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default EmissionSelectQuestion;
