import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconArrowleft from '../images/emissions/arrow-left.svg';
import { faAngleLeft, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import Layout from '../components/layout';
import SEO from '../components/seo';
import EmissionCategories from '../components/Emissions/EmissionCategories';
import EmissionResult from '../components/Emissions/EmissionResult';
import CountrySelector from '../components/Emissions/CountrySelector';
import { FirebaseContext } from '../utils/firebase-provider.js';
import { emissionCalculator } from '../utils/emission-utils/index.js';

import '../styles/global.scss';
import '../styles/emissions.scss';

const EmissionRefine = () => {
    return (
        <Layout navBarType="userProfile">
            <SEO title="Refine Emission profile" />
            <section className="act section">
                <div className="profile-flex-container">
                    <div className="profile-flex-components">
                        <header className="emissionsHeader">
                            <Link
                                to="/profile/myemissions"
                                aria-label="Back to My Emissions"
                                className="emissionsHeader__icon"
                                state={{ navFrom: 'userEmissions' }}
                            >
                                <div className="emissionHeader_back_icon">
                                    <img src={iconArrowleft} className="emission_back_icon" />
                                </div>
                                <span className="emissionsHeader__back_label">My Emissions</span>
                            </Link>
                            <div>
                                <h2 className="emissionsHeader__title">Refine My Emission</h2>
                                <h2 className="emissionsHeader__subtitle h6">
                                    Select a category to improve your estimate.
                                </h2>
                            </div>
                        </header>
                        <EmissionCategories />
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default EmissionRefine;
