import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { FirebaseContext } from '../../utils/firebase-provider.js';
import { getEmissionImage } from '../../utils/emission-utils/EmissionQuestions.js';
import EmissionQuestion from './EmissionQuestion';
import iconArrowDown from '../../images/emissions/arrow-down.svg';

import '../../styles/global.scss';
import '../../styles/emissions.scss';

// lookup amount of questions [answered, total]
const countQuestionAnswered = (questions, userProfile) => {
    let count = 0;
    let answered = 0;
    for (let i = 0; i < questions.length; i++) {
        if (questions[i].type == 'group') {
            const [countGroup, answeredGroup] = countQuestionAnswered(
                questions[i].questions,
                userProfile
            );
            count += countGroup;
            answered += answeredGroup;
        } else {
            count += 1;
            if (
                questions[i].hasOwnProperty('userProfile') &&
                userProfile &&
                userProfile.hasOwnProperty(questions[i].userProfile)
            ) {
                answered += 1;
            }
        }
    }
    return [count, answered];
};

const EmissionCategory = props => {
    const context = useContext(FirebaseContext);
    const [displayQuestions, toggleQuestions] = useState(false);
    const questions = props.questions ? props.questions : [];
    const [countQuestions, countAnswered] = countQuestionAnswered(questions, context.userProfile);
    const displayAllBorder = props.displayAllBorder ? true : false;
    return (
        <div
            className={
                displayAllBorder ? 'emissionCategory emissionBottomBorder' : 'emissionCategory'
            }
        >
            <div
                className="emissionCategoryHeader"
                onClick={() => toggleQuestions(!displayQuestions)}
            >
                <div className="emissionTitleSection">
                    <img
                        src={getEmissionImage(props.title)}
                        alt={props.title}
                        className="emissionIcon"
                    />
                    <span className="emissionTitle">{props.title}</span>
                </div>
                <div className="emissionTitleSection">
                    <div className="emissionTitle_CategorySection">
                        {/* <span>1.14t CO₂e</span> */}
                        <img
                            className={displayQuestions ? 'emissionArrowRotate' : 'emissionArrow'}
                            src={iconArrowDown}
                        />
                    </div>
                </div>
            </div>
            {displayQuestions ? (
                <div className="emissionQuestions">
                    {questions.map(question => (
                        <EmissionQuestion {...question} />
                    ))}
                </div>
            ) : null}
        </div>
    );
};

EmissionCategory.propTypes = {
    title: PropTypes.string,
    questions: PropTypes.arrayOf(PropTypes.object),
    co2generated: PropTypes.string,
};

export default EmissionCategory;
