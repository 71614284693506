import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { FirebaseContext } from '../../utils/firebase-provider.js';
import { CountryResourcesHelper } from '../../utils/emission-utils/CountryResourcesHelper.js';

import '../../styles/global.scss';
import '../../styles/emissions.scss';

const CountrySelector = props => {
    const context = useContext(FirebaseContext);
    const selectedCountryCode = context.userProfile ? context.userProfile['userCountryCode'] : '';
    const onCountryChange = country => context.updateUserProfile('userCountryCode', country);
    return (
        <select
            onChange={event => onCountryChange(event.target.value)}
            className="emissionCountrySelector"
        >
            <option value="">Select your country</option>
            {Object.entries(CountryResourcesHelper.getListCountries()).map(
                ([countryCode, countryName]) => (
                    <option
                        value={countryCode}
                        selected={countryCode == selectedCountryCode ? 'selected' : ''}
                    >
                        {countryName}
                    </option>
                )
            )}
        </select>
    );
};

CountrySelector.propTypes = {};

export default CountrySelector;
