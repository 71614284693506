import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { FirebaseContext } from '../../utils/firebase-provider.js';
import EmissionSelectQuestion from './EmissionSelectQuestion.js';
import {
    customEmissionQuestionValue,
    shouldHideEmissionQuestion,
} from '../../utils/emission-utils/EmissionQuestion.js';

import '../../styles/global.scss';
import '../../styles/emissions.scss';

const EmissionQuestion = props => {
    const context = useContext(FirebaseContext);

    const onChange = value => {
        const updateValues = customEmissionQuestionValue(props, value);
        if (updateValues) {
            Object.entries(updateValues).forEach(([key, value]) =>
                context.updateUserProfile(key, value)
            );
        } else {
            console.error(
                'Question with unassigned userProfile, check emission-questions.js',
                props.title
            );
        }
    };

    const value = props.userProfile ? context.userProfile[props.userProfile] : '';

    if (shouldHideEmissionQuestion(props, context)) {
        return null;
    }

    let input = <input />;
    let inlineInputWithTitle = false;
    switch (props.type) {
        case 'input':
            inlineInputWithTitle = inlineInputWithTitle || props.inlineInputWithTitle;
            input = (
                <input
                    className="emissionInput"
                    value={value}
                    onChange={event => onChange(event.target.value)}
                />
            );
            let inputUnitList = props.units;
            if (inputUnitList?.length > 0) {
                input = (
                    <div className="emissionInputUnit">
                        {input}
                        <span className="emissionUnit">
                            <select>
                                {Object.entries(inputUnitList).map(([index, unit]) => (
                                    <option value={index}>Annual {unit}</option>
                                ))}
                            </select>
                        </span>
                    </div>
                );
            }
            break;
        case 'slider':
            const limit = props.limit ? props.limit : {};
            input = (
                <span className="emissionSlider">
                    <div className="emissionSliderGroup">
                        <p>{limit.min}</p>
                        <input
                            type="range"
                            min={limit.min}
                            max={limit.max}
                            step={limit.step ? limit.step : 1}
                            className="emissionInputRange"
                            value={value}
                            onChange={event => onChange(event.target.value)}
                        />
                        <p>{limit.max}</p>
                    </div>
                    <input
                        type="number"
                        className="emissionInput"
                        value={value}
                        onChange={event => onChange(event.target.value)}
                    />
                </span>
            );
            break;
        case 'select':
            input = (
                <EmissionSelectQuestion options={props.options} value={value} onChange={onChange} />
            );
            break;
        case 'bool':
            input = (
                <EmissionSelectQuestion
                    options={[
                        {
                            name: 'Yes',
                            value: true,
                        },
                        {
                            name: 'No',
                            value: false,
                        },
                    ]}
                    value={value}
                    onChange={onChange}
                />
            );
            break;
        case 'group':
            input = (
                <div>
                    {props.questions.map(question => (
                        <EmissionQuestion {...question} />
                    ))}
                </div>
            );
            break;
        default:
            break;
    }

    if (inlineInputWithTitle)
        return (
            <div className="inputBlock">
                <p>{props.title}</p>
                <span className="inputBlockInline">{input}</span>
            </div>
        );

    return (
        <div className="emissionQuestion">
            <p>{props.title}</p>
            {input}
        </div>
    );
};

const propTypeQuestion = {
    title: PropTypes.string,
    type: PropTypes.oneOf(['input', 'select', 'bool', 'group', 'slider']),
    userProfile: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    filterUpdateValue: PropTypes.func,
    isHidden: PropTypes.func,
};

EmissionQuestion.propTypes = {
    ...propTypeQuestion,
    questions: PropTypes.arrayOf(PropTypes.shape(propTypeQuestion)),
};

export default EmissionQuestion;
