export const customEmissionQuestionValue = (questionProps, value) => {
    if (!questionProps.userProfile) return null;

    if (questionProps.userProfile === 'distanceDriven' && value === '0') {
        return {
            distanceDriven: value,
            carType: -1,
        };
    } else if (questionProps.userProfile === 'carType' && value === -1) {
        return {
            carType: value,
            distanceDriven: '0',
        };
    } else if (questionProps.userProfile === 'spendingOnNewGoodsMonthly') {
        return {
            spendingOnNewGoodsMonthly: value,
            spendingOnNewGoodsAnnually: Number.parseFloat(value * 12).toFixed(2),
        };
    } else if (questionProps.userProfile === 'spendingOnNewGoodsAnnually') {
        return {
            spendingOnNewGoodsMonthly: Number.parseFloat(value / 12).toFixed(2),
            spendingOnNewGoodsAnnually: value,
        };
    }

    return { [questionProps.userProfile]: value };
};

export const shouldHideEmissionQuestion = (questionProps, context) => {
    return (
        (questionProps.userProfile === 'carMilesPerGallon' && context.userProfile['carType'] < 2) ||
        (questionProps.userProfile === 'averageNumberOfPeopleIntheCar' &&
            context.userProfile['carType'] === -1) ||
        (questionProps.title === 'Motorcycle details' &&
            context.userProfile['distanceMotorcycle'] === '0')
    );
};
